@import-normalize;

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

/* Prevents selection of divs */
div {
    /* -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none; */
    user-select: none;
}

button {
    text-transform: uppercase;        
    color: #181;
    background: none;
    border: 1px solid green;
    letter-spacing: 2px;
    padding: 2px 10px;
}

button:hover {
    background-color: #bdeeb1;
}

button:active {
    background-color: #9ceb89;
}

body {
    max-width: 524px;
    margin-top: 20px;
    margin-right: auto;
    margin-left: auto;
    padding: 10px;
    font-size: 20px;
    font-family: Arial, Helvetica, sans-serif;
    color: #555;
    background-color: white;
}

h1 {
    color: rgb(240, 171, 130);
    margin: 0 0 20px 1px;
    text-align: center;
    text-shadow: 1px 1px #999;
}

#tested-in {
    font-size: 85%;
    text-align: center;
}

#game {
    position: relative;
    text-transform: uppercase;
    margin-bottom: 40px;
}

aside {
    padding: 0 1px;
}

#status-display {
    margin-bottom: 8px;
}

#status-display p {
    display: inline-block;
    height: 50px;
    line-height: 50px;
    margin-right: 8px;
    margin-bottom: 5px;
}

#status-display p ~ button {
    vertical-align: text-top;
}

.thumbnail-container {
    margin-left: 10px;
}

.levelpic_thumbnail {
    width: 50px;
    height: 50px;
	border-radius: 50%;
	vertical-align: middle;
}

#showpic {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0);
    visibility: hidden;
    pointer-events: none;
    z-index: 999;
}

#showpic:target {
    background: rgba(0, 0, 0, 0.75);
    visibility: visible;
    pointer-events: auto;
}

#showpic_content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

#showpic_content img {
    min-width: 200px;
    max-width: 300px;
    width: 100%;
    border-radius: 30px;
}

#hidepic {
    display: block;
    position: absolute;
    right: 0;
    top: -70px;
    width: 60px;
    height: 60px;
    background-image: url('./img/close-icon.png');
    background-size: 100%;
}

/*
Visually hidden but available for screen readers
https://snook.ca/archives/html_and_css/hiding-content-for-accessibility
*/
.sr-only {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}

#play-button {
    font-size: 1.2em;
    margin-right: 35px;
    min-width: 4em;
}

#moves-remaining {
    display: inline-block;
    font-size: 0.8em;
}

#hint {
    display: inline-block;
    margin-right: 25px;
}

#hint a {
    text-decoration: none;
    outline: 1px solid rgba(0, 153, 0, 0.5);
    padding: 3px;
    color: rgba(0, 153, 0, 0.8);
    text-transform: uppercase;
    font-size: 85%;
    letter-spacing: 1.6px;
}

#hint a:focus, #hint a:hover {
    outline: 1px dotted rgba(0, 230, 0, 1);
    color: rgba(0, 230, 0, 1);
}

#play-button, #moves-remaining, #hint {
    margin-bottom: 12px;
}

#holding-area {
    min-height: 104px;
    margin-top: 20px;
    outline: 1px dashed cadetblue;
    padding: 2px;
}

#song-container > div {
    display: inline-block;
    width: 100px;
    height: 100px;
    background-color: cornflowerblue;
    font-size: 16px;
    vertical-align: bottom;
    border: 1px solid white;
}

.drop-target {
    background-image: url('./img/fillme.png');
    background-size: 100%;
}

.drag-button {
    display: inline-block;
    width: 100px;
    height: 100px;
    cursor: pointer;
    vertical-align: bottom; /* ? */
    outline: 1px solid white;
    background-image: url('./img/playicon.png');
    background-size: 50%;
    background-repeat: no-repeat;
    background-position: 50% 50%;
}

#song-container .drag-button {
    width: 98px;
    height: 98px;
}

.active.drag-button {
    background-image: none;
}

.active.simple-time-segment, .active.drop-target {
    background-image: none;
    background-color: #222;
    animation-name: color-pulse;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}
@keyframes color-pulse {
    0% {
      background-color:hsl(219, 31%, 48%);
    }
    50% {
      background-color: cornflowerblue;
    }
    100% {
      background-color: hsl(219, 61%, 50%);
    }
}

.success {
    color: darkcyan;
}

.failure {
    color: orangered;
}

.blink-me {
    animation: blinker 1.1s linear 4;
}
  
@keyframes blinker {  
    50% { opacity: 0; }
}

#gamecompleted {
    text-align: center;
}

.imagecredit {
    font-size: 60%;
    margin: 10px 0;
}

#code-link {
    font-size: 0.85rem;
    color: cornflowerblue;
}


/* --- SVGs --- */

.playing-svg, .muted-svg {
    display: none;
    position: relative;
    width: 50px;
    height: 40px;
    top: 50%;
    transform: translateY(-50%);
    margin: auto;
}
.active .playing-svg, .active .muted-svg {
    display: block;
}


.speaker, .wave {
    stroke: #333; /* TODO variable */
    stroke-width: 5;
}
.speaker {
    fill: #333; /* TODO variable */
    stroke-linejoin: round;
}
.wave {
    fill: none;
    stroke-linecap: round;
}
@keyframes fadein {
    50% { opacity: 50%; }
}
.active .wave {
    animation: fadein 0.8s linear infinite;
}
.active .wave2 {
    animation-delay: 0.3s;
}
.active .wave3 {
    animation-delay: 0.6s;
}


.muted-svg {
    width: 49px;
}
.cross-line-1, .cross-line-2 {
    fill: none;
    stroke:#333; /* TODO variable */
    stroke-width: 4;
    stroke-linecap: round;
}
